<template>
  <div class="footer">
    <div class="copy-right">
      <span>版权所有@2021-2022 轩辕方舟</span>
      <p class="beian">
        <a href="https://beian.miit.gov.cn/" target="_blank"> 京ICP备2023013970号</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.footer {
  width: 100%;
  background: rgba(66, 70, 70, 1);
  .copy-right {
    clear: both;
    width: 100%;
    padding: 15px;
    // height: 60px;
    background: rgba(47, 51, 51, 1);
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
    //  line-height: 120px;
    p {
      // height: 60px;
    }
    a {
      color: rgba(153, 153, 153, 1);
    }
  }
}
</style>