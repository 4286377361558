<template>
  <div class="header-container">
    <div class="header-warp">
      <div class="header-logo"></div>
      <div class="header-nav">
        <ul class="ul-father">
          <li class="li-itme" v-for="(item,index) in arr" :class="{select: $route.path === item.url}" :key="index" v-on:click="itemClick(index)">
            <router-link v-bind:to="item.url">
              {{ item.title }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "app-header",
  data () {
    return {
      curSelect: -1,
      arr: [
        {
          title: "首页",
          url: "/"
        },
        {
          title: "解决方案",
          url: "/solution"
        },
        {
          title: "产品介绍",
          url: "/product"
        },
        {
          title: "最新发布",
          url: "/news"
        },
        // {
        //   title: "企业文化",
        //   url: "/culture"
        // },
        {
          title: "合作伙伴",
          url: "/friend"
        },
        {
          title: "关于我们",
          url: "/about"
        }
      ]
    }
  },
  methods: {
    itemClick: function (index) {
      this.curSelect = index;
    }
  }
}
</script>

<style lang="scss" scoped>
.header-container {
  background-color: #ffffff;
  width: 100%;
  .header-warp {
    width: 1200px;
    height: 100px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .header-logo {
      margin-top: 10px;
      margin-bottom: 10px;
      display: block;
      width: 100%;
      height: 100%;
      background: url(../assets/res/logo.jpg) no-repeat 0 0;
    }
    .header-nav {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      // margin: 30 auto;
      margin-top: 30px;
      margin-bottom: 30px;
      .ul-father {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .li-itme {
          width: 100px;
          line-height: 35px;
          height: 35px;
          a {
            margin: 0 5px;
            display: block;
            font-size: 16px;
            font-weight: bolder;
            font-family: 微软雅黑;
            color: #001e4d;
            letter-spacing: 0;
            text-shadow: none;
            -webkit-text-shadow: none;
            -o-text-shadow: none;
            -ms-text-shadow: none;
            -moz-text-shadow: none;
          }

          a:hover {
            color: #ac0000;
            border-bottom: 3px #ac0000 solid;
          }
        }
        .select a {
          color: #ac0000;
          border-bottom: 3px #ac0000 solid;
        }
      }
    }
  }
}
</style>
