import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/solution',
    name: 'Solution',
    component: () => import('../views/Solution.vue')
  },
  {
    path: '/product',
    name: 'Product',
    component: () => import('../views/Product.vue')
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('../views/News.vue')
  },
  {
    path: '/news/:id',
    name: 'News',
    component: () => import('../views/OneNews.vue')
  },
  // {
  //   path: '/oneNews',
  //   name: 'onenews',
  //   component: () => import('../views/OneNews.vue')
  // },
  {
    path: '/oneNews',
    name: 'onenews',
    component: () => import('../news/20230628.vue')
  },
  {
    path: '/culture',
    name: 'Culture',
    component: () => import('../views/Culture.vue')
  },
  {
    path: '/friend',
    name: 'Friend',
    component: () => import('../views/Friend.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
