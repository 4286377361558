<template>
  <div id="app">
    <app-header></app-header>
    <!-- <div class="banner-container">
      <div class="banner-box">
        <img src="./assets/res/title4.jpg" alt="" class="banner-img">
      </div>
    </div> -->

    <div id="v-content" v-bind:style="{minHeight: Height+'px'}">
      <router-view v-if="isRouterAlive" />
    </div>
    <app-footer> </app-footer>
  </div>
</template>

<script>

import Header from './components/app-header'
import Footer from './components/app-footer'

export default {
  name: 'App',
  components: {//局部注册组件这里，可能会定义多个组件，所以component这个单词加上“s”
    "app-header": Header,
    "app-footer": Footer,
    // 'app-nav': Navbar
  },
  data () {
    return {
      Height: 0,
      isRouterAlive: true
    }
  },
  mounted () {
    //动态设置内容高度 让footer始终居底   header+footer的高度是75 250
    this.Height = document.documentElement.clientHeight - 372;
    //监听浏览器窗口变化
    window.onresize = () => { this.Height = document.documentElement.clientHeight - 372 };
  },
  methods: {
  }
}
</script>


<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  .banner-container {
    width: 1200px;
    margin: 0 auto;
    .banner-box {
      margin-top: 5px;
      .banner-img {
        width: 1200px;
        height: 256px;
      }
    }
  }
}
</style>
