<template>

    
  <div class="home-container">
    <div class="banner-container">
      <div class="banner-box">
        <img src="../assets/res/title4.jpg" alt="" class="banner-img">
      </div>
    </div>

    <div class="main-container">
      <div class="product-box">
        <div class="box-title">
          <div class="title">
            产品展示
          </div>
        </div>
        <!-- <div class="pro-box">
          <vue-waterfall-easy :imgsArr="imgsArr" :imgWidth="240"></vue-waterfall-easy>
        </div> -->

        <ul class="pro-item">
          <li v-for="(item,index) in proArr" class="pro-pic" :key="index">
            <img :src="item.propic" alt="">
          </li>
        </ul>
        <ul class="pro-item">          
          <li v-for="(item,index) in infoArr" class="pro-pic" :key="index">           
            <div class="core-item"><p>{{item.info}}</p></div>
          </li>
        </ul>
         <!-- <div class="pro-item">
          <li v-for="(item,index) in proArr" class="pro-pic" :key="index">
            <img :src="item.propic" alt="">
          </li>
        </div> -->
      </div>

      <div class="solution-box">
        <div class="box-title">
          <div class="title">
            解决方案
          </div>
        </div>
        <div class="content-box">
          <div class="content">        
            <p>
              物联可信解决方案
            </p>
            <p>
              由于软件安全性以及网络安全性，在最近二十年内相对已经得到显著加固，黑客的攻击路径开始沿着技术堆栈“下移”，反而越来越聚焦在硬件、芯片及其嵌入式软件（固件）作为攻击目标，硬件不像软件，可以被基本安全控件轻松地访问或扫描，尽管其攻击和分析需要依赖更专业的设备，被利用的难度更大，但同时，因为硬件的安全漏洞生命周期往往比较长，给攻击者提供了足够的时间窗口来利用或者施展各种攻击手段。 我司在针对智能装备底层硬件，包括芯片、固件以及进行整机的攻击检测能力平台和加固解决方案，该解决方案具备较好的可一致性，典型应用包括智能家居、智能表计、智能门锁、车联网、金融、电力、交通、党政等行业。；
            </p>
            <p>
              商用密码应用解决方案
            </p>          
            <p>
              芯片安全实验室
            </p>
            <p>
              密改服务
            </p>
          </div>
          <div class="con-pic">
            <img src="../assets/res/iot2.jpg" class="show-pic" alt="">
          </div>
        </div>
      </div>

      
      <div class="company-box">
        <div class="box-title">
          <div class="title">
            公司简介
          </div>
        </div>
        <div class="content-box">
          <div class="content">
            <p>
              北京轩辕方舟科技有限公司成立于2017年11月30日，注册地位于北京市门头沟区莲石湖西路98号院5号楼703室G12（集群注册），法定代表人为邹继先。经营范围包括技术服务、开发、推广、转让、咨询；信息系统集成服务；销售计算机软硬件及外设、机械设备、电子产品；计算机系统服务；经济信息咨询；会议服务；货物进出口；技术进出口；机械设备租赁；技术检测。（市场主体依法自主选择经营项目，开展经营活动；依法须经批准的项目，经相关部门批准后依批准的内容开展经营活动；不得从事国家和本市产业政策禁止和限制类项目的经营活动。）。
            </p>
            <p>
              &nbsp;
            </p>
            <p>
            &nbsp;
            </p>
            <p>
              北京轩辕方舟科技有限公司
            </p>
            <p>
              地址：北京市西城区德胜置业大厦1号楼1901
            </p>
            <p>
              联系人：许先生
            </p>
            <p>
              电话：13910782545
            </p>
            <p>
              邮箱：xujinxuan@xyark.com
            </p>
          </div>
          <div class="con-pic">
            <img src="../assets/res/map3.png" class="show-pic" alt="">
          </div>
        </div>
        <div class="content">          
            联系我们          
        </div>
      

      </div>
    
    </div>
  </div>
</template>

<script>

// import vueWaterfallEasy from 'vue-waterfall-easy'

export default {
  name: 'Home',
  data () {
    return {
      proArr: [
        {
          propic: require("../assets/res/cp-ipsecVpn.jpg")
        },
        {
          propic: require("../assets/res/cp-passwordcard.jpg")
        },
        {
          propic: require("../assets/res/cp-keyServer.jpg")
        }
      ],
      infoArr: [
        {
          "info": "IPSec VPN安全网关是专门为用户内部网络数据的传输、加密、完整性保护、鉴别等需求而设计，为用户提供数据安全加密、IPSec VPN功能。 可以实现政府、集团公司、连锁经营大中型企业的总部与各个级别分支机构、移动办公人员的远程接入联网需求，可以为远程接入提供透明的数据的传输加密，完整性校验等安全防护。"
        },
        {
          "info": "密码卡产品，是以PCI Express为总线接口的高速密码设备，支持对称运算、杂凑运算、非对称运算、产生真随机数等密码运算功能，同时，提供完备的密钥管理及存储功能。 C100 (MINI) 版是一款针对MINI PCI-E接口设计的加密卡。"
        },
        {
          "info": "服务器密码机是以国产密码技术为核心，具有物理安全保护措施以及完善密钥管理机制的硬件密码设备。服务器密码机为应用层密码设备，支持SM1/SM2/SM3/SM4国密算法，为业务系统提供应用层密码服务，包括密钥管理、消息验证、数据加密、签名的产生和验证等，保证业务数据产生、传输、接收到处理整个过程的安全性、有效性、完整性、不可抵赖性，被广泛应用于金融、社保、电力、公交、证券等应用领域。"
        }
      ],
      imgsArr: [
        {
          "src": require("../assets/res/cp-ipsecVpn.jpg"),
          "href": "https://www.baidu.com",
          "info": "一些图片描述文字"
        },
        {
          "src": require("../assets/res/cp-passwordcard.jpg"),
          "href": "https://www.baidu.com",
          "info": "一些图片描述文字"
        },
        {
          "src": require("../assets/res/cp-ipsecVpn.jpg"),
          "href": "https://www.baidu.com",
          "info": "一些图片描述文字"
        },
      ]
    }
  },
  components: {
    // vueWaterfallEasy
  }
}
</script>

<style lang="scss" scoped>
.home-container {
  width: 100%;
  // display: flex;
  .banner-container {
    width: 1200px;
    margin: 0 auto;
    .banner-box {
      margin-top: 5px;
      .banner-img {
        width: 1200px;
        height: 256px;
      }
    }
  }
  .main-container {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    .box-title {
      width: 100%;
      border-bottom: 2px #999 solid;
      align-items: center;
      // display: flex;
      .title {
        margin-bottom: -2px;
        // display: flex;
        align-items: center;
        width: 80px;
        line-height: 32px;
        color: #333;
        font-size: 18px;
        font-weight: 400;
        text-shadow: none;
        border-bottom: 2px #ac0000 solid;
      }
    }
    .pro-box {
      display: block;
      height: 400px; // 父元素必须要有高度
      width: 100%; // 如果已经是block元素，则可以忽略
      .vue-waterfall-easy-container
        .vue-waterfall-easy-scroll[data-v-ded6b974] {
        overflow-y: hidden !important;
      }
    }

    .product-box {
      // display: flex;
      margin-top: 10px;
      width: 100%;
      align-items: center;
      justify-content: center;
      .pro-item {
        display: flex;
        // width: 1240px; 
        margin: auto 10px;
        flex-direction: row;
        justify-content: center;
        // justify-content: space-between;
        margin-top: 10px;
        .pro-pic {
          display: flex;
          margin: auto 20px;
          // height: 426px;
          // width: 350px; 
            //    width:50%;
            // height: 50%;         
          justify-content: center;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .company-box {
      margin-top: 10px;
      width: 100%;
      .content-box {
        display: flex;
        flex-direction: row;
        margin-top: 5px;
        .content {
          margin: 20px 5px 0 0;
          width:100%;
          p {
            font-size: 14px;
            line-height: 1.8;
            padding: 0;
            margin: 0;
            white-space: normal;
            word-wrap: break-word;
            word-break: normal;
            overflow: hidden;
            width: 100%;
            text-align: left;
            text-indent: 2em;
          }
        }
        .con-pic {
          margin-top: 5px;          
          margin-bottom:5px;
          width: 100%;
          height: 100%;
        }
        .show-pic{
          margin-top:5px;
          margin-bottom:5px;
          width: 100%;
          height: 100%;
        }
      }
    }

    .solution-box {
      margin-top: 10px;
      margin-bottom: 20px;
      width: 100%;
      .content-box {
        display: flex;
        flex-direction: row;
        margin-top: 5px;
        align-items: center;
        .content {
          margin: 20px 5px 0 0;
          width:100%;
          p {
            font-size: 14px;
            line-height: 1.8;
            padding: 0;
            margin: 0;
            white-space: normal;
            word-wrap: break-word;
            word-break: normal;
            overflow: hidden;
            width: 100%;
            text-align: left;
            text-indent: 2em;
          }
        }
        .con-pic {
          margin-top: 20px;          
          width: 100%;
          height: 100%;
        }
        .show-pic{
          margin-top:5px;
          margin-bottom:5px;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>

